@import '../../styles/variables';

.not-found-board {
    margin-left: 28px;
    margin-right: 20px;
    text-align: center;
}

.not-found-board-icon-wrapper {
    margin-bottom: 50px;
    margin-top: 128px;
}

.not-found-board-label {
    color: #7D838A;
    font-size: 15px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.15px;
    margin-bottom: 10px;
}

.not-found-board-link {
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
}
