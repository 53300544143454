@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto-300.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto-regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto-500.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto-900.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/roboto-thin.ttf');
    font-weight: 100;
    font-style: normal;
}
