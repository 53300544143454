@import '../../styles/variables';

$footerHeight: 40px;
$footerMargin: 12px;

.layout {
    min-width: 1366px - 30px;
    position: relative;
    display: flex;
    padding: 0;
    min-height: 100vh;

    & > .sidebar {
        position: relative;
        padding: 90px 0;
        width: $left-sidebar-width;
        margin-left: $left-sidebar-margin-x;
        margin-right: $left-sidebar-margin-x;
    }

    & > .content {
        width: 940px;
        padding-bottom: 24px;
        box-sizing: border-box;
        min-height: 100vh;
        display: flex;
        flex-direction: column;

        & > .content-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0;
        }

        .content-head-controls {
            display: flex;
        }

        & > .content-inner {
            flex: 1 1 auto;
            background: $color-snowy;
            border-radius: 4px * 2;
            position: relative;
            padding: 22px 42px 42px;
        }
    }

    .sidebar-logo-wrapper {
        position: absolute;
        bottom: 24px;
        left: 28px;
    }

    .sidebar-content {
        padding-top: 64px;
    }

    .sidebar-theme-control {
        display: none;
    }

    .mobile-search {
        display: none;
    }

    .sidebar-mobile-lang-switcher {
        display: none;
    }


    // Телефоны.
    @media (max-width: 766px) {
        flex-wrap: wrap;
        min-width: 940px;

        & > .sidebar {
            width: calc(100vw - 2*16px);
            margin-left: 0;
            margin-right: 0;
            padding: 18px 16px 20px 16px;

            .burger-logo {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .hidden {
                display: none;
            }
        }

        .sidebar-content {
          padding-top: 20px;
        }

        & > .content {
            padding-top: 0;

            .content-inner {
                padding-top: 0;
            }
        }

        .sidebar-content.hidden {
            display: none;
        }

        .sidebar-logo-wrapper {
            display: none;
        }

        .sidebar-theme-control {
            display: block;
        }

        .content > .content-head {
            display: none;
        }

        &.layout-mobile-menu-open {
            & > .sidebar {
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin-left: 0;
                margin-right: 0;
                width: auto;
                z-index: 100;
                background: var(--sidebar-background);
            }

            .mobile-search {
                display: block;
                margin-bottom: 26px;
            }

            .sidebar-mobile-lang-switcher {
                display: block;
                padding-right: 42px;
                padding-top: 30px;
                text-align: center;
            }
        }
    }
}
