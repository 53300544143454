@import '../../styles/variables';

.search-result {
    position: relative;
    padding: 10px;
    max-height: inherit;
    box-sizing: border-box;
    overflow: auto;
}

.search-result-item {
    padding: 10px 30px;
    color: var(--search-item-color);
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background: var(--search-item-background-hover);
    }

    // Плашка "текущая страница".
    .current-page-label {
        color: #7D838A;
        background: #F2F4F7;
        font-size: 11px;
        line-height: 11px;
        padding: 3px 4px;
        border-radius: 4px;
        margin-left: 10px;
        display: none;
    }
}

.search-result-item-header {
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    color: var(--search-result-item-header-color);
}

.search-result-item-body {
    font-size: 16px;
    line-height: 24px;
    color: var(--search-result-item-body-color);
}

.search-result-item-selection {
    background: var(--search-item-selection);
}

.search-result-item-footer {
    margin-top: 5px;
    color: var(--search-result-item-footer-color);
    font-size: 13px;
    line-height: 15px;
}

.search-result-item-link {
    display: block;
    text-decoration: none;

    // Ранее посещенная страница.
    &:visited .search-result-item-header,
    &:visited .search-result-item-body {
        color: var(--search-result-item-color-visited);
    }

    // Текущая страница.
    &.active {
        .current-page-label {
            display: inline-block;
        }
    }
}

@media (max-width: 766px) {
    .search-result-item:hover {
        background: var(--mobile-search-item-background-hover);
    }

    .search-result-item-header {
        color: var(--mobile-search-result-item-header-color);
    }

    .search-result-item-body {
        color: var(--mobile-search-result-item-body-color);
    }

    .search-result-item-footer {
        color: var(--mobile-search-result-item-footer-color);
    }

    .search-result-item-selection {
        color: var(--mobile-search-item-selection-color);
        background: var(--mobile-search-item-selection-background);
    }
}