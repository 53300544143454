@import '../../styles/variables';

.filter-menu-wrapper {
    margin-left: 28px;
    margin-right: 20px;
}

.styleguide-menu-loading {
    z-index: 20;
    background-color: rgba(255, 255, 255, 0.7);
    text-align: center;
    border-radius: 8px;
}

.styleguide-menu {
    margin-left: 28px;
    margin-right: 20px;
}

.menu-list {
    font-size: 15px;
    line-height: 30px;
    font-weight: 500;
    text-align: left;

    margin: 0;
    padding: 0;
    list-style: none;

    &-item {
        margin-bottom: 15px;
    }

    &-link {
        cursor: pointer;
        color: var(--menu-color);
        text-decoration: none;

        &:hover,
        &.active {
            color: var(--menu-color-active);
        }

        &.active {
            cursor: default;
        }

        &:focus {
            outline: none;
        }
    }
}

.menu-back {
    font-size: 17px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 30px;

    &-link {
        color: var(--menu-back-color);
        cursor: pointer;
        position: relative;

        &:hover {
            color: var(--menu-back-color-hover);

            .menu-back-icon {
                stroke: var(--menu-back-icon-color-hover);
            }
        }
    }

    &-icon {
        width: 7px;
        height: 14px;
        position: absolute;
        top: 3px;
        left: -17px;
        stroke: var(--menu-back-icon-color);
    }
}

@media (max-width: 766px) {
    .menu-list {
        // Высота экрана - верхний отступ до меню - нижний отступ.
        max-height: calc(100vh - 340px - 16px);
        overflow: auto;
    }
}