@import '../../styles/index';

.live-preview {
  background: rgba(31,31,34, .56);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  z-index: $z-index-demo-ui;

  > .close {
    position: fixed;
    right: 24px;
    top: 24px;
    cursor: pointer;

    .primary-fill {
      fill: $color-asphalt-03;
    }

    &:hover {
      .primary-fill {
        fill: $color-snowy;
      }
    }
  }

  > .frame-container {
    position: fixed;
    left: 80px;
    right: 80px;
    top: 0;
    bottom: 0;

    > .frame {
      border: none;
      width: 100%;
      height: 100%;
    }
  }

}