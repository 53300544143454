@import '../../styles/variables';

.search {
    position: relative;

    .search-input {
        margin: 0;
        padding: 8px 16px 8px 42px;
        width: 674px;
        height: 32px;
        font-size: 14px;
        line-height: 20px;
        box-sizing: border-box;
        border: none;
        color: var(--menu-search-input-color);
        background: var(--menu-search-input-bg-color);
        border-radius: 8px;
        position: relative;
        z-index: $z-index-search-input;

        &::placeholder {
            color: var(--menu-search-input-placeholder-color);
        }

        &:focus + .search-icon path {
            fill: var(--menu-search-input-icon-fill-focus);
        }
    }

    .search-icon {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 10px;
        top: 6px;
        z-index: $z-index-search-popup;

        path {
            fill: var(--menu-search-input-icon-fill);
        }
    }

    .search-clear {
        cursor: pointer;
        position: absolute;
        width: 16px;
        height: 16px;
        right: 10px;
        top: 8px;
        z-index: $z-index-search-popup;
    }

    .search-popup-overlay {
        background: var(--search-popup-overlay-background);
        opacity: 0.7;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: $z-index-search-overlay;
    }
}

.search-overflow-hidden {
    overflow: hidden;
}

@media (max-width: 766px) {
    .search {
        .search-input {
            width: calc(100vw - 40px);
            margin-left: 4px;
        }

        .search-popup-overlay {
            display: none;
        }
    }
}