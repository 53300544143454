:root {
    --main-bg-color: #f2f4f7;
    --heading-color: #2e2f36;
    --theme-icon-background-color: #ffffff;
    --menu-back-color: #2E2F36;
    --menu-back-color-hover: #1F1F22;
    --menu-back-icon-color: #2E2F36;
    --menu-back-icon-color-hover: #1F1F22;
    --menu-color: #7D838A;
    --menu-color-active: #2E2F36;
    --menu-search-input-bg-color: #ffffff;
    --menu-search-input-color: #1F1F22;
    --menu-search-input-icon-fill: #B2B8BF;
    --menu-search-input-icon-fill-focus: #1F1F22;
    --menu-search-input-placeholder-color: #7D838A;
    --channel-switcher-color: #7D838A;
    --channel-switcher-color-active: #1F1F22;
    --channel-switcher-border-color: transparent;
    --channel-switcher-border-color-hover: #686977;
    --channel-switcher-border-color-active: #1F1F22;
    --status-bar-title-color: #9A9EA0;
    --status-bar-status-description-color: #36393C;
    --development-status-tip-background: #1F1F22;
    --development-status-tip-color: #FFF;
    --sidebar-background: #F2F4F7;
    --search-popup-background: #FFF;
    --mobile-search-popup-background: #F2F4F7;
    --search-popup-overlay-background: #D0D7DD;
    --search-item-color: #2E2F36;
    --search-item-background-hover: #F2F4F7;
    --mobile-search-item-background-hover: transparent;
    --search-item-selection: #FFD9A0;
    --mobile-search-item-selection-color: inherit;
    --mobile-search-item-selection-background: #E5FCF7;
    --search-result-item-header-color: #1F1F22;
    --search-result-item-body-color: #2E2F36;
    --search-result-item-footer-color: #7D838A;
    --search-result-item-color-visited: #7D838A;
    --mobile-search-result-item-header-color: #1F1F22;
    --mobile-search-result-item-body-color: #2E2F36;
    --mobile-search-result-item-footer-color: #7D838A;
    --language-control-button-color: #1F1F22;
}

.theme-dark {
    --main-bg-color: #1f1f22;
    --heading-color: #fff;
    --theme-icon-background-color: #686977;
    --menu-back-color: #F2F4F7;
    --menu-back-color-hover: #FFFFFF;
    --menu-back-icon-color: #F2F4F7;
    --menu-back-icon-color-hover: #FFFFFF;
    --menu-color: #7D838A;
    --menu-color-active: #D0D7DD;
    --menu-search-input-bg-color: #ffffff;
    --menu-search-input-color: #1F1F22;
    --menu-search-input-icon-fill: #D0D7DD;
    --menu-search-input-icon-fill-focus: #1F1F22;
    --menu-search-input-placeholder-color: #7d838a;
    --channel-switcher-color: #7D838A;
    --channel-switcher-color-active: #D0D7DD;
    --channel-switcher-border-color: transparent;
    --channel-switcher-border-color-hover: #7D838A;
    --channel-switcher-border-color-active: #D0D7DD;
    --status-bar-title-color: #A3A3A3;
    --status-bar-status-description-color: #F2F4F7;
    --development-status-tip-background: #FFF;
    --development-status-tip-color: #2E2F36;
    --sidebar-background: #1F1F22;
    --search-popup-background: #FFF;
    --mobile-search-popup-background: #1F1F22;
    --search-popup-overlay-background: #1F1F22;
    --search-item-color: #2E2F36;
    --search-item-background-hover: #F2F4F7;
    --mobile-search-item-background-hover: transparent;
    --search-item-selection: #FFD9A0;
    --mobile-search-item-selection-color: #1F1F22;
    --mobile-search-item-selection-background: #90D0CC;
    --search-result-item-header-color: #1F1F22;
    --search-result-item-body-color: #2E2F36;
    --search-result-item-footer-color: #7D838A;
    --search-result-item-color-visited: #7D838A;
    --mobile-search-result-item-header-color: #D0D7DD;
    --mobile-search-result-item-body-color: #D0D7DD;
    --mobile-search-result-item-footer-color: #686977;
    --language-control-button-color: #ffffff;
}
