@import '../../styles/variables';

.development-status {
    position: relative;
    display: inline-block;
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-weight: 700;
    border-radius: 11px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    box-sizing: border-box;

    &.design {
        background: #ff0000;
        // Визуально D стоит не по центру кружка без этого значения.
        padding-left: 1px;
    }
    
    &.platform {
        background: #0eb757;
        // Визуально P стоит не по центру кружка без этого значения.
        padding-left: 1px;
    }
    
    &.wip {
        background: #ffa800;
    }
    
    &.with-tip {
        cursor: pointer;

        &:hover .development-status-tip {
            display: block;
        }
    }
}

.development-status-tip {
    display: none;
    text-align: left;
    font-weight: normal;
    width: 165px;
    background: var(--development-status-tip-background);
    color: var(--development-status-tip-color);
    box-shadow: 0 2px 7px 0 rgba(31, 31, 34, 0.25);
    border-radius: 8px;
    position: absolute;
    top: 40px;
    left: 50%;
    padding: 16px;
    font-size: 12px;
    line-height: 16px;
    transform: translateX(-50%);
    z-index: 10;

    &::before {
        content: '';
        background: var(--development-status-tip-background);
        width: 16px;
        height: 16px;
        position: absolute;
        left: 50%;
        top: -8px;
        font-size: 12px;
        line-height: 16px;
        transform: translateX(-50%) rotate(45deg);
    }
}
