@import '../../styles/variables';

.channelSwitcher {
    position: relative;
    margin-bottom: 30px;
}

.tabs {}

.inlineTabs {
    justify-content: space-around;
    flex-flow: row wrap;
    align-items: stretch;
    display: flex;
}

.tabItem {
    font-family: 'Roboto', serif;
    flex: 1;
    color: var(--channel-switcher-color);
    background: none;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 16px;
    padding: 8px 0;
    white-space: nowrap;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        border-radius: 4px;
        background: var(--channel-switcher-border-color);
    }

    &::-moz-focus-inner {
        border: none;
    }

    & + .tabItem {
        margin-left: 1px;
    }

    &:hover,
    &:focus {
        color: var(--channel-switcher-color-active);

        &::after {
            background: var(--channel-switcher-border-color-hover);
        }
    }

    &.selected,
    &.selected:hover {
        color: var(--channel-switcher-color-active);
        cursor: default;

        &::after {
            background: var(--channel-switcher-border-color-active);
        }
    }
}
