@import '../../../../../styles/index';

.channel-button {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    .channel-icon {
        width: 24px;
        height: 24px;
    }

    .channel-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        opacity: .7;
    }

    .channel-label {
        margin-left: 12px;
        font-size: 12px;
        line-height: 20px;
        color: $color-asphalt-01;
        user-select: none;
    }

    &:hover .channel-label {
        color: $color-snowy;
    }

    &:hover .channel-icon-wrapper {
        opacity: 1;
    }

    &:focus {
        outline: none;
    }

    @media (min-width: 1700px) {

        .channel-label {
            font-size: 18px;
        }

        .channel-icon {
            width: 36px;
            height: 36px;
        }
    }
}
