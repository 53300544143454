@import '../../styles/index';

/**
 * Стили отображения приложения
 * - Спиннер на время загрузки
 * - Общий бэкграунд
 */
html,
body,
.app {
    height: 100%;
    min-height: 100%;
    position: relative;
}

html {
    -webkit-text-size-adjust: none;
}

body {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', 'Arial', 'PT_sans', sans-serif;
    height: 100%;
    letter-spacing: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin: 0;
    background: #f2f4f7;
    background: var(--main-bg-color);
}

button {
    border: none;
    padding: 0;
    background-color: #fff;
}

:focus {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}

.overflow-hidden {
    overflow: hidden;
}

a {
    color: $color-sky-02;

    &:hover {
        color: $colorLinkHover;
    }

    &:focus-visible {
        outline: 1px solid $colorLinkFocusOutline;
        outline-offset: 1px;
    }
}

// Fix iframe от React devtool - https://stackoverflow.com/questions/69051008/react-injecting-iframe-with-max-z-index-on-reload-after-changes-development
body > iframe {
    display: none;
}
