@import '../../styles/variables';

.search-popup {
    position: absolute;
    top: 42px; // 32px (размер поля) + 10px (отступ)
    left: 0;
    width: 674px;
    max-height: calc(100vh - 124px); // 136px = 62px сверху + 62px снизу
    background: var(--search-popup-background);
    border-radius: 8px;
    box-shadow: 0 16px 24px rgba(31, 31, 34, 0.25);
    z-index: $z-index-search-popup;

    &.faded::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 10px;
        right: 10px;
        height: 60px;
        background: linear-gradient(0, #FFFFFF 21.07%, rgba(255, 255, 255, 0.90625) 35.85%, rgba(255, 255, 255, 0) 68.29%);
        pointer-events: none;
    }

    &.hidden {
        display: none;
    }
}

.search-symbol-count {
    font-size: 14px;
    line-height: 20px;
    color: #7D838A;
    padding: 20px 40px;
}

.search-not-found {
    color: #2E2F36;
    font-size: 16px;
    line-height: 24px;
    padding: 60px 40px;
    text-align: center;
}

@media (max-width: 766px) {
    .search-popup {
        position: fixed;
        width: auto;
        right: 0;
        left: 0;
        bottom: 0;
        top: 115px;
        box-shadow: none;
        border-radius: 0;
        background: var(--mobile-search-popup-background);
    }

    .search-symbol-count {
        text-align: center;
    }
}