.back-top {
    width: 184px;
    height: 60px;
    z-index: 10;
    align-self: flex-end;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;

    position: sticky;
    position: -webkit-sticky;
    bottom: 20px;
    margin-top: -102px;
    margin-bottom: 42px;
    margin-right: 42px;
}

.back-top-image {
    width: 60px;
    height: 60px;
}