@import '../../../styles/index';

$header-height: 44px;
$external-vertical-padding: 60px;
$external-horizontal-padding: 60px;

.start-page-body {
    background: #000;

    // Кнопка "Оставить отзыв".
    #atlwdg-trigger {
        display: none;
    }
}

.start-page {
    min-height: 700px;
    height: 100vh;
    box-sizing: border-box;
    padding: $external-vertical-padding $external-horizontal-padding;
    --logo-color: #7d838a;

    .logo-icon-start-page {
        width: auto;
        height: 70px;
    }

    .logo-sber {
        width: auto;
        height: 50px;
    }

    video,
    img {
        position: fixed;
        min-width: 100%;
        min-height: 100%;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: -1;
    }

    h1 {
        font-weight: 300;
        font-size: 40px;
        line-height: 42px;
        color: $color-snowy;
        margin: 0 0 20px;
    }

    p {
        margin: 0 0 40px;
        font-size: 20px;
        line-height: 26px;
        color: $color-snowy;
        font-weight: 100;
    }

    main {
        position: absolute;
        left: 60px;
        bottom: 60px;
    }

    .button-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .channel-button:not(:last-child) {
        margin-bottom: 20px;
    }

    .start-page-header {
        display: flex;
        justify-content: space-between;
    }

    footer {
        position: absolute;
        right: 60px;
        bottom: 60px;
        font-size: 8px;
        line-height: 12px;
        text-align: right;
        color: $color-snowy;
    }

    .gradient {
        z-index: -1;
        width: 42%;
        position: fixed;
        top: 0;
        bottom: 0;

        &.left {
            left: 0;
            background: linear-gradient(89.95deg, #000000 0.64%, #000000 23.09%, rgba(0, 0, 0, 0.784384) 51.07%, rgba(0, 0, 0, 0.6) 64.47%, rgba(0, 0, 0, 0.276941) 80.45%, rgba(0, 0, 0, 0) 91.22%);
        }

        &.right {
            right: -1px;
            background: linear-gradient(89.9deg, #000000 0.39%, rgba(0, 0, 0, 0.6) 36.68%, rgba(31, 31, 34, 0) 64.41%);
            transform: matrix(-1, 0, 0, 1, 0, 0);
        }
    }

    @media (min-width: 1700px) {
        min-height: 1050px;

        .logo-icon-start-page {
            height: 80px;
        }

        .logo-sber {
            height: 60px;
        }

        h1 {
            font-size: 80px;
            line-height: 84px;
            margin-bottom: 50px;
        }

        p {
            font-size: 40px;
            line-height: 52px;
            margin-bottom: 50px;
        }

        footer {
            font-size: 10px;
            line-height: 16px;
        }
    }

    @media (max-width: 769px) {
        min-height: 650px;
        padding: 30px;

        main {
            left: 30px;
            bottom: 158px;
        }

        .logo-icon-start-page {
            height: 40px;
        }

        .logo-sber {
            height: 37px;
        }

        p {
            margin-bottom: 30px;
        }

        .gradient {
            &.left {
                width: 100%;
                background: linear-gradient(89.95deg, #000000 0.64%, #000000 23.09%, rgba(0, 0, 0, 0.784384) 51.07%, rgba(0, 0, 0, 0.6) 78.08%, rgba(0, 0, 0, 0.276941) 112.13%, rgba(0, 0, 0, 0) 136.25%);
            }

            &.right {
                display: none;
            }
        }

        footer {
            right: 30px;
            bottom: 30px;
        }
    }

}
