@import '../../styles/index';

.triplex-resources {
  margin: 0 0 30px 0;
  display: flex;
  font-size: 0;
}

.triplex-resource {
  width: 30px;
  height: 30px;
  display: block;
  margin-right: 10px;
  border-radius: 15px;

  &:last-child {
    margin-right: 0;
  }

  &:focus-visible {
    outline: 1px solid $colorLinkFocusOutline;
    outline-offset: 0;
  }

  &:hover svg > rect {
    fill: $color-asphalt-01;
  }
}