@keyframes spin360 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

%animation-spin-360 {
    animation: spin360 1s linear infinite;
}
