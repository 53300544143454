@import '../../styles/variables';

.footer {
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 40px;
    padding: 0 4px * 10;
    margin-top: 12px;
    line-height: 24px;
    color: $color-asphalt-01;
}
