.language-control-inner {
  flex-grow: 1;
  margin-left: 42px;
}

.language-control-main {
  height: 22px;
  margin-bottom: 20px;
}

.language-control-button {
  margin-left: 20px;
  opacity: .3;
  background: none;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:hover,
  &:disabled {
    opacity: 1;
  }

  &:disabled {
    pointer-events: none;
  }
}

.language-control-icon {
  rect {
    stroke: var(--language-control-button-color);
  }
  path {
    fill: var(--language-control-button-color);
  }
}

// На главной цвет не меняется.
.start-page .language-control-icon {
  rect {
    stroke: #1F1F22;
  }
  path {
    fill: #1F1F22;
  }
}

