@import '../../styles/variables';

.logo-wrapper {
    display: flex;
    justify-content: center;
    max-width: 360px;
    overflow: hidden;

    .logo-icon {
        height: 65px;
    }

    .logo-link {
        &:focus {
            outline: none;
        }
    }

    @media (max-width: 766px) {
        .logo-icon {
            width: 90px;
            height: auto;
        }
    }
}

