//// Sky.
$color-sky-02: #1358bf; // Текстовые ссылки, бордер инфо-уведомлений, иконки.

//// Graphite.
$color-graphite-01: #1f1f22; // Основной цвет текста на странице для всех заголовков и наборного текста на светлом фоне, активные пункты табов.
$color-graphite-02: #565b62;

// Asphalt.
$color-asphalt-01: #7d838a; // Активные навигационные табы, названия столбцов в таблицах, плейсхолдеры, текст описания, подписи.
$color-asphalt-02: #b2b8bf; // Иконоки, вспомогательные ссылки.
$color-asphalt-03: #d0d7dd; // Разделители, границы инпутов, границы блоков, иконки в таблицах.
$color-asphalt-04: #e4e8eb; // Фон второго уровня, разделители таблиц, сетка графиков, Hover навигационных табов.
$color-asphalt-05: #f2f4f7; // Фон в состоянии Disabled и правом сайдбаре на главной странице, фон для хедера страницы, фон заголовка таблицы, фон шапки лайтбокса, футер лайтбокса, фон трекера, фон аккордеона.

// Snowy.
$color-snowy: #ffffff;

//menu
$menu-hover-background: $color-asphalt-01;
$menu-active-background: #303030;

$colorLinkHover: #0eb757;
$colorLinkFocusOutline: #ffdd64;

$left-sidebar-width: 356px;
$left-sidebar-margin-x: 32px;

$z-index-search-overlay: 100;
$z-index-search-input: 200;
$z-index-search-popup: 300;
$z-index-demo-ui: 1000;


