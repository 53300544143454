@import '../../styles/animation';

.spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px 0;

    .icon-spin {
        @extend %animation-spin-360;
    }

    .text-wrapper {
        margin-top: 8px;
        text-align: center;
    }
}
