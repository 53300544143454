@import '../../styles/index';

$colorSvgHover: #7d838a;

.board-grid {
    display: flex;
    justify-content: space-between;
}

.board-header {
    border-bottom: 1px solid #D0D7DD;
    margin-bottom: 30px;
}

.board-header-main {
    .board-text:first-child {
        margin-bottom: 4px;
    }
    .board-text:nth-child(2) {
        margin-bottom: 12px;
    }
    .board-text:last-child {
        margin-bottom: 35px;
    }
}

.board-image {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.board-sub-header-medium + .board-image {
    margin-top: 10px;
}

.board-header-side-panel-wrapper {
    display: flex;
}

.board-header-list {
    margin-top: 35px;
    margin-bottom: 22px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.board-sub-header {
    display: flex;
    align-items: center;
    gap: 5px;

    &-large {
        margin-top: 50px;
        margin-bottom: 10px;
    }

    &-medium {
        .development-status.platform {
            top: -1px;
        }
    }

    .board-text {
        margin-bottom: 0;
    }
}

.board-warning,
.board-content {
    margin-bottom: 30px;
    box-sizing: border-box;
}

.board-warning {
    .board-text {
        margin-bottom: 0;
    }
}

.board-content {
    > *:last-child {
        margin-bottom: 0;
    }
}

// Отступ между Content блоком и Warning блоком - 20px.
.board-content + .board-warning {
    margin-top: -10px;
}

// Отступ между Warning блоков и SubHeader размера L - 30px.
.board-warning + .board-content > .board-sub-header-large:first-child {
    margin-top: 30px;
}

// Верхний отступ у SubHeader размера L, если он идет первым элементом Board.
.board-content:first-child > .board-sub-header-large:first-child {
    margin-top: 0;
}

.board-left-col {
    width: 632px;

    .board-content:last-child,
    .board-warning:last-child {
        margin-bottom: 0;
    }
}

.board-right-col {
    width: 184px;
}

.board-col {
    // Колонки, расположенные под другими колонками добавляют отступ между строк.
    margin-top: 10px;
    display: inline-block;
    vertical-align: top;
    margin-right: 40px;

    // Первые 2 колонки не имеют верхний отступ т.к. над ними нет других колонок.
    &:nth-of-type(-n + 2) {
        margin-top: 0;
    }

    &:nth-of-type(even) {
        margin-right: 0;
    }
}

.board-col-3 {
    width: (592/4) + px;
}

.board-col-6 {
    width: (592/2) + px;
}

.board-col-9 {
    width: 592/4 * 3px;
}

.board-list {
    > li {
        margin-bottom: 16px;
    }
}

.board-link {
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;

    svg {
        vertical-align: middle;
    }

    &:hover svg path {
        fill: $colorSvgHover;
    }
}

.sticky-sidebar {
    position: sticky;
    top: 20px;
}

.board-list {
    .development-status {
        display: none;
    }
}

.display-linebreak {
    white-space: pre-line;
}

.board-text,
.board-spoiler {
    margin-bottom: 20px;
}

.board-spoiler-target {
    color: $color-sky-02;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;

    &:hover {
        color: $colorLinkHover;
    }

    &:focus-visible {
        outline: 1px solid $colorLinkFocusOutline;
        outline-offset: 1px;
    }
}

.board-spoiler-content {
    overflow: hidden;
    transition: max-height 0.5s ease;
}

.list {
    .list-item {
        padding-left: 24px;
        margin-bottom: 10px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 4px;
            top: 6px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #FF0000;
        }

        .board-text {
            margin-bottom: 0;
        }
    }
}

.ordered-list-primary {}

.ordered-list-primary-item, 
.ordered-list-secondary-item {
    position: relative;
    padding-left: 20px;
    margin-bottom: 12px;

    .designation {
        position: absolute;
        left: 0;
        top: 2px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        background: #FF0000;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .board-text {
        margin-bottom: 0;
    }
}

.ordered-list-secondary-item .designation {
    background: #A3A3A3;
}